import { getCookie } from 'cookies-next';

const ISSERVER = typeof window === "undefined";

export function getCookieStorage(key: string) {
  if (!ISSERVER) {
    const value = getCookie(key, { httpOnly: false });
    if (!value) return null;
    try {
      return value;
    } catch (error) {
      return null;
    }
  }
}

export function getLocalStorageValue(key: string) {
  if (!ISSERVER) {
    const value = localStorage.getItem(key);
    if (!value) return null;
    try {
      return value;
    } catch (error) {
      return null;
    }
  }
}

export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function removeLocalStorage(key?: any) {
  localStorage.removeItem(key);
}

export function changeTextSex(key: string) {
  if (key === "MALE") return "Nam";
  if (key === "FEMALE") return "Nữ";
  if (key === "OTHER") return "Khác";
  return "";
}

export function clearCookies() {
  document.cookie =
    "ICAN_ID.access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "ICAN_ID.id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function addVersion(url: any) {
  const number = Math.random();
  if (url.includes('graph.facebook.com')) {
    return url;
  } else {
    return `${url}?v${number}`;
  }

}

export const RE_DIGIT = new RegExp(/^\d+$/);

export const phoneRegExp = /^\+84\s\d{10 || 9}$/;

export const phoneVali = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const specialLetterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const passwordRegExp =
  /(?=[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])(?=.{8,}).*$/g;

export function onValidateMail(v?: string) {
  if (v) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v);
  } else {
    return false;
  }
}

export function onValidatePhone(v: string) {
  return /^0((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
    v
  );
}
