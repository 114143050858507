var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wikfbbKCrO5dcBA_UUThk"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";


Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENV || '',
    dsn: "https://bd1834f2b8ea401493f90cb575e5ee0e@sentry.galaxyedu.io/52",
    tracesSampleRate: 1.0,
});