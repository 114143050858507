import { getLocalStorageValue, getCookieStorage } from "@/utils/utils";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { setCookie } from 'cookies-next';

import en from "../locales/en/en.json";
import vi from "../locales/vi/vi.json";

interface State {
  locale: any;
  changeLanguage: any;
  setLocales: any;
  messages?: any;
}

const initialState: State = {
  locale: "vi",
  changeLanguage: null,
  setLocales: "",
  messages: vi,
};

const LanguageContext = createContext<State>(initialState);

export const LanguageProvider = ({ children }: any) => {
  const [locale, setLocales] = useState("vi");

  const changeLanguage = (newLanguage: string) => {
    setLocales(newLanguage);
    localStorage.setItem("locale", newLanguage);
    setCookie("locale", newLanguage, { httpOnly: false });
    window.location.reload();
  };

  useEffect(() => {
    console.log(locale, "cookieLang")
    // const locale = getLocalStorageValue("locale");
    const localeCookie = getCookieStorage("locale");
    if (localeCookie) {
      setLocales(localeCookie);
    }
  }, [locale]);

  const messages = {
    en: en,
    vi: vi,
  };

  const value = useMemo(() => {
    return {
      locale,
      changeLanguage,
      setLocales,
      //  @ts-ignore
      messages: messages[locale],
    };
  }, [locale]);
  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
