import clsx from 'clsx';
import { IoClose } from 'react-icons/io5';
import { IModalProps } from './index.type';
import styles from './styles.module.scss';

const HModal: React.FC<IModalProps> = (props) => {
  const {
    size = 'md',
    overlay = true,
    className = '',
    bodyClasses = '',
    header,
    footer,
    position = 'center',
    showCloseButton = true,
  } = props;

  const closeModel = () => {
    typeof props.onChanged === 'function' && props.onChanged(false);
  };

  return (
    <>
      <div
        className={clsx(
          styles.modal,
          {
            [styles.overlay]: overlay,
            [styles[`modal-${size}`]]: true,
            [styles[`modal-${position}`]]: true,
            [className]: true,
          },
          { [styles.closed]: !props.visible },
        )}
      >
        <div className={clsx(styles.modalContainer)}>
          {header && (
            <header className="flex items-center justify-between p-2 md:p-3">
              <h2 className="flex-1 text-2xl font-semibold">
                {typeof header === 'function' ? header() : header}
              </h2>
            </header>
          )}
          {showCloseButton && (
            <div
              className="absolute z-50 text-neutral-500 transform top-3 right-3 hover:text-primary text-[26px] font-black"
              onClick={closeModel}
            >
              <IoClose className="text-4xl font-bold cursor-pointer" />
            </div>
          )}
          <div className={clsx('p-2 md:p-3', bodyClasses)}>
            {props.children}
          </div>
          {footer && (
            <footer className="flex bg-transparent">{footer()}</footer>
          )}
        </div>
      </div>
    </>
  );
};

export default HModal;
