import { AuthProvider, useAuth } from "@/context/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import "@/styles/globals.css";
import { LanguageProvider, useLanguage } from "@/context/language";
import HModalWarning from "@/components/common/HModalWarning";
import Head from "next/head";
import { useRouter } from "next/router";
import favicon from "../assets/favicon.ico";
import { UserProvider } from "@icanid/icanid-sdk-nextjs/client";

const Noop: FC = ({ children }: any) => <>{children}</>;
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: any) {
  const { user } = pageProps;
  const Layout = (Component as any).Layout || Noop;
  const [isOnline, setIsOnline] = useState(true);
  const { messages } = useLanguage();
  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(true);
    const handleOfflineStatus = () => setIsOnline(false);

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    };
  }, []);

  const router = useRouter();

  if (isOnline === false) {
    return (
      <HModalWarning
        showButton={false}
        title={messages.offline}
        message={messages.offlineDesc}
        onClose={() => router.push("/")}
        onClick={() => router.push("/")}
      />
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider user={user}>
        <LanguageProvider>
          <Layout pageProps={pageProps}>
            <Head>
              <title>ICAN ID - Galaxy Education</title>
              <meta name="description" content="ICAN ID - Galaxy Education" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <link
                rel="shortcut icon"
                type="image/x-icon"
                href={favicon.src}
              />
            </Head>
            <Component {...pageProps} />
          </Layout>
        </LanguageProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
