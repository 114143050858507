import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { IButtonProp } from "./index.type";
import styles from "./styles.module.scss";

const HButton = (prop: React.PropsWithChildren<IButtonProp>) => {
  const {
    variant = "default",
    size = "md",
    outline = false,
    loading = false,
    ghost = false,
    tag: Tag = "button",
    rounded = "round-md",
    disabled = false,
    ...props
  } = prop;
  const btnClasses = {
    [`${styles[variant]}`]: !!variant,
    [`${styles[size]}`]: !!size,
    [`${styles[rounded]}`]: !!rounded,
    [styles.outline]: !!outline,
    [styles.disabled]: disabled,
    [styles.loading]: !!loading,
    [styles.ghost]: ghost,
  };
  return Tag === "a" || props.href ? (
    <Link
      //  @ts-ignore
      href={props.href}
      legacyBehavior
    >
      <a
        {...props}
        className={clsx(styles.btn, btnClasses, props.className || "")}
      >
        {loading && (
          <span className={styles.loadingIcon}>
            <AiOutlineLoading3Quarters />
          </span>
        )}
        {props.children}
      </a>
    </Link>
  ) : (
    <Tag
      {...props}
      className={clsx(
        Tag !== "div" && styles.btn,
        btnClasses,
        props.className || ""
      )}
    >
      {loading && (
        <span className={styles.loadingIcon}>
          <AiOutlineLoading3Quarters />
        </span>
      )}
      {props.children}
    </Tag>
  );
};

export default HButton;
